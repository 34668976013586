<div mat-dialog-content>
  <div class="title">
    <div class="unit">
      <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_CODE' | translate }}</div>
      <div class="lab-value">{{ order.orderID }}</div>
    </div>
    <div class="unit web-only">
      <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_NAME' | translate }}</div>
      <div class="lab-value">{{ order.receiverName }}</div>
    </div>
    <div class="unit web-only">
      <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_MOBILE_NUMBER' | translate }}</div>
      <div class="lab-value">{{ order.phoneNumber }}</div>
    </div>
    <div class="date-title web-only">
      {{ 'ORDERS_PAGE.ORDER_STATUS' | translate }}
    </div>
    <div class="btn status-badge">{{ returnOrderStatus(order.status) | translate }}</div>
  </div>
  <div
    class="order-preference-container"
    *ngIf="shouldShowUpOrderPreference && orderPreference?.preferredProfitDiscountPercentage >= 0"
  >
    <app-order-preference
      [shouldShowEditButton]="false"
      [orderPreferenceFromOrderDetails]="orderPreference"
    ></app-order-preference>
  </div>
  <div *ngIf="viewProducts" class="main">
    <div class="inline-div">
      <img loading="lazy" src="../../../assets/img/prods-i.png" class="icon" />
      <div class="title-text">
        {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCTS' | translate }}
      </div>
      <div *ngIf="!data.draft" class="btn btn-message web-only" (click)="openChatModel(order)">
        {{ 'ORDERS_PAGE.SEND_MESSAGE_BTN_LABEL' | translate }}
      </div>
    </div>
    <div class="table">
      <div class="table-header">
        <div class="prod-name">
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_NAME' | translate }}
        </div>
        <div class="prod-details">
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_QUANTITY' | translate }}
        </div>
        <div class="prod-details">
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_PRICE' | translate }}
        </div>
        <div class="prod-details">
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_PROFIT' | translate }}
        </div>
      </div>
      <div
        *ngFor="let orderLine of order.orderLines; let index = index"
        class="table-row"
        [ngClass]="{ 'table-row--upsold': orderLine.upsold }"
      >
        <p class="caption1--bold upsold-label" *ngIf="orderLine.upsold">
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.UPSOLD_ITEM' | translate }}
        </p>
        <div class="image-cell web-only">
          <div class="image-container">
            <img
              loading="lazy"
              class="product-image"
              [alt]="orderLine.productName + '\'s image'"
              [src]="orderLine.productPicture"
            />
          </div>
        </div>
        <div class="prod-name-text">
          {{ orderLine.productName }}
          <div class="product-variants">
            <span *ngIf="orderLine.productColor" class="color-variant">
              <span>{{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_COLOR' | translate }}:&nbsp;</span>
              <span
                [ngStyle]="{ backgroundColor: orderLine.productColorHex }"
                class="color-ball"
              ></span>
              <span>&nbsp;{{ orderLine.productColor }}</span>
            </span>
            <span *ngIf="orderLine.productSize" class="size-variant"
              >{{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_SIZE' | translate }}:
              {{ orderLine.productSize }}</span
            >
          </div>
        </div>
        <div class="prod-details-text">
          {{ orderLine.quantity }}
          {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_PIECES' | translate }}
        </div>
        <div class="prod-details-text">
          {{ orderLine.totalPrice }}
          {{ (currency?.arabicName | currencyTranslate | translate | currencyShortName) || '' }}
        </div>
        <div class="prod-details-text">
          {{ orderLine.totalMerchantProfit }}
          {{ (currency?.arabicName | currencyTranslate | translate | currencyShortName) || '' }}
        </div>
      </div>
    </div>
    <div *ngIf="!data.draft" class="footer-message">
      {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.ORDER_FOOTER_MESSAGE' | translate }}
    </div>
  </div>
  <div *ngIf="!viewProducts" class="main">
    <div class="table">
      <div class="table-header">
        <div class="prod-details margin-text">تاريخ</div>
        <div class="prod-details margin-text">حالة</div>
        <div *ngIf="data.TransitEvents" class="prod-details margin-text">السبب</div>
        <div *ngIf="!data.TransitEvents" class="prod-details margin-text web-only">Notes</div>
        <div *ngIf="!data.TransitEvents" class="prod-details margin-text web-only">
          Suspended Reason
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details margin-text web-only">
          Customer Rejected Reason
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details margin-text web-only">
          Failed Attempts
        </div>
      </div>
      <div *ngFor="let event of transitEvents; let index = index" class="table-row">
        <div class="prod-details-text margin-text">{{ event.timestamp }}</div>
        <div class="prod-details-text margin-text">{{ event.state }}</div>
        <div *ngIf="data.TransitEvents" class="prod-details margin-text">
          {{ event.reason }}
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details-text margin-text web-only">
          {{ event.notes }}
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details-text margin-text web-only">
          {{ event.suspendedReason }}
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details-text margin-text web-only">
          {{ event.customerRejectedReason }}
        </div>
        <div *ngIf="!data.TransitEvents" class="prod-details-text margin-text web-only">
          {{ event.failedAttemptNote }}
        </div>
      </div>
      <div *ngFor="let event of waybillTrackingDetail; let index = index" class="table-row">
        <div class="prod-details-text margin-text">{{ event.date }}</div>
        <div class="prod-details-text margin-text">
          {{ event.waybillStatus }}
        </div>
        <div *ngIf="data.TransitEvents" class="prod-details margin-text">
          {{ event.reason }}
        </div>
      </div>
      <div *ngFor="let event of aramexTrackingResults; let index = index" class="table-row">
        <div class="prod-details-text margin-text">
          {{ epochToJsDate(event.UpdateDateTime) }}
        </div>
        <div class="prod-details-text margin-text">
          {{ event.UpdateDescription }}
        </div>
        <div *ngIf="data.TransitEvents" class="prod-details margin-text">
          {{ event.Comments }}
        </div>
      </div>
      <div *ngFor="let event of vhubsTrackingResults; let index = index" class="table-row">
        <div class="prod-details-text margin-text">
          {{ event.updatedDate }}
        </div>
        <div class="prod-details-text margin-text">
          {{ event.shipmentStatus }}
        </div>
        <div *ngIf="data.TransitEvents" class="prod-details margin-text">
          {{ event.reason }}
        </div>
      </div>
    </div>
  </div>
</div>
